import { memo, useEffect, useMemo } from "react"
import { useSanity } from "@app/hooks/useSanity"
import { SearchResultsLoading } from "../Results/SearchResultsLoading"
import { SearchResultsEmpty } from "../Results/SearchResultsEmpty"
import { ArticleCard } from "@components/Article/ArticleCard"
import { useAlgoliaSearch } from "@app/providers/algolia"
import { Grid } from "@chakra-ui/react"

const SearchResultsBlogs: React.FC = () => {
  const { searchTerm } = useAlgoliaSearch()
  const { getSanity, sanityItems: articles, loading } = useSanity()

  useEffect(() => {
    const projection = [
      `{
        _id,
        title,
        url,
        image {
          alt,
          asset -> {
            url
          }
        },
        categories[][0..0]->{
          title
        },
        handle {
          current
        },
        attributes {
          publishedAt
        }
      }`,
    ]

    const searchQuery = searchTerm ? `&& [title, description] match "*${searchTerm}*"` : ""

    const query = [`*[_type == "article" && !(_id in path('drafts.**')) ${searchQuery}]`].concat(projection).join("")

    getSanity(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, articles])

  const hasArticles = useMemo(() => !!articles?.length, [articles])

  if (loading) return <SearchResultsLoading />
  if (!hasArticles) return <SearchResultsEmpty />

  return (
    <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} columnGap={[0, 4]} rowGap={[10, 12]}>
      {articles?.map((article: any) => (
        <ArticleCard key={article?._id} article={article} />
      ))}
    </Grid>
  )
}

const MemoSearchResultsBlogs = memo(SearchResultsBlogs)
export { MemoSearchResultsBlogs as SearchResultsBlogs }
