import { useAlgoliaSearch } from "@app/providers/algolia"
import type { Props } from "@app/pages/search"
import type { PageProps } from "@root/types/global"

// Purpose - to check whether the reactify search term matches a collection name which is tagged as a brand in sanity,
// and to return that matching collection back
// Note: this hook must be used as a child of a reactify provider

type useBrandCollectionProps = {
  brands: PageProps<Props>["brands"]
}

const noCollectionObj = {
  brandCollection: {},
  hasMatchingBrandCollection: false,
}

const useBrandCollection = ({ brands }: useBrandCollectionProps) => {
  const { searchTerm } = useAlgoliaSearch()
  if (!searchTerm) return noCollectionObj

  const allBrandTitles = brands?.edges?.map(({ node }) => node.title) || []
  const matchingBrandTitle = allBrandTitles?.find(brandTitle => brandTitle?.toLowerCase()?.trim() === searchTerm?.toLowerCase()?.trim())
  const searchTermMatchesBrandTitle = !!matchingBrandTitle

  if (!searchTermMatchesBrandTitle) return noCollectionObj

  const matchingBrandCollection = brands?.edges?.find(({ node }) => node.title === matchingBrandTitle)?.node

  return {
    brandCollection: matchingBrandCollection,
    hasMatchingBrandCollection: true,
  }
}

export { useBrandCollection }
