import { memo } from "react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { Text, HStack, Tag } from "@chakra-ui/react"
import { useAlgoliaSearch } from "@app/providers/algolia"

type SearchSuggestedTermsProps = {
  terms?: GatsbyTypes.SanityPageSearch["suggestedSearchTerms"]
}

const SearchSuggestedTerms: React.FC<SearchSuggestedTermsProps> = ({ terms }) => {
  const locales = useLocale(LOCALE_KEYS.SEARCH)
  const { handlePageChange, setSearchTerm, updateSearchParam, handleTopFilterChange } = useAlgoliaSearch()

  const handleTermClick = (term: string) => {
    handleTopFilterChange("show-all")
    updateSearchParam(term)
    handlePageChange(1)
    setSearchTerm(term)
  }

  if (!terms) return null

  return (
    <>
      <HStack wrap="wrap" columnGap={[1.5, 2]} rowGap={2} spacing={0}>
        <Text fontSize={["sm", "md"]} w={["full", "auto"]} mb={[3, 0]}>
          {locales?.searchPageSuggestedSearchesTitle}
        </Text>
        {terms?.map(({ _key, term }: any) => (
          <Tag key={_key} variant="search-suggested" cursor="pointer" onClick={() => handleTermClick(term)}>
            {term}
          </Tag>
        ))}
      </HStack>
    </>
  )
}

const MemoSearchSuggestedTerms = memo(SearchSuggestedTerms)
export { MemoSearchSuggestedTerms as SearchSuggestedTerms }
